import { Button, Col, Divider, Modal, Row } from "antd";
import { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import MainContext from "../../contexts/MainContext";

const ModalImpayes = ({ tabFacturesImpayes, open, onOk }) => {
  const { viewportWidth } = useContext(MainContext);
  const { t } = useTranslation();

  return (
    <Modal
      title={t("FACTURES IMPAYÉES")}
      open={open}
      onOk={onOk}
      closable={true}
      onCancel={onOk}
      footer={[
        <Button key="back" onClick={onOk}>
          {t("Fermer")}
        </Button>,
      ]}
      width={viewportWidth < 1081 ? viewportWidth - 50 : viewportWidth - 300}
    >
      <Row>
        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
          {t("Ref. Facture")}
        </Col>
        <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "center" }}>
          {t("Net à payer")}
        </Col>
        <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "center" }}>
          {t("Montant reglé")}
        </Col>

        <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "right" }}>
          Date
        </Col>
      </Row>
      <Divider style={{ marginTop: "12px" }} />
      <Row>
        {tabFacturesImpayes && tabFacturesImpayes.length > 0
          ? tabFacturesImpayes?.map((facture) => (
              <Fragment key={facture?.NumeroDocument}>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p>{facture.NumeroDocument}</p>
                  </div>
                </Col>
                <Col
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  style={{ textAlign: "center" }}
                >
                  <div>
                    <p>{facture.NetAPayer}€</p>
                  </div>
                </Col>
                <Col
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  style={{ textAlign: "center" }}
                >
                  <div>
                    <p>{facture.MontantRegle}€</p>
                  </div>
                </Col>
                <Col
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  style={{ textAlign: "right" }}
                >
                  <div>
                    <p>
                      {
                        new Date(
                          parseInt(facture?.Date?.split("(")[1]?.split(")")[0])
                        )
                          .toLocaleString()
                          .split(" ")[0]
                      }
                    </p>
                  </div>
                </Col>
                <Divider />
              </Fragment>
            ))
          : tabFacturesImpayes?.map((facture) => facture.NumeroDocument)}
      </Row>
    </Modal>
  );
};

export default ModalImpayes;
