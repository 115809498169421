import { Button, Card } from "antd";
import { useContext, useEffect, useState } from "react";
import MainContext from "../../contexts/MainContext";
import { useParams } from "react-router-dom";
import { Loader } from "../loader";
import { ModalReliquat } from "../modal-client-reliquat";
import { useTranslation } from "react-i18next";

const DashboardClientReliquats = () => {
  const [reliquatCount, setReliquatCount] = useState(null);
  const [isModalReliquatOpen, setIsModalReliquatOpen] = useState(false);

  const {
    getAllArticlesByDocTypeByClient,
    allArticlesByClientByBonDeCommande,
    isLoadingReliquat,
  } = useContext(MainContext);
  const { idClient } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    getReliquatCount(allArticlesByClientByBonDeCommande);
  }, [allArticlesByClientByBonDeCommande]);

  useEffect(() => {
    getAllArticlesByDocTypeByClient(idClient, 1);
  }, [idClient]);

  const showModalReliquat = () => {
    setIsModalReliquatOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalReliquatOpen(false);
  };

  const getReliquatCount = (articlesTab) => {
    let count = 0;

    articlesTab?.forEach((art) => {
      if (art.Quantite && art.Quantite !== NaN) {
        count += art.Quantite;
      }
    });
    setReliquatCount(count);
  };

  return (
    <Card
      title={t("RELIQUATS / EN ATTENTE")}
      style={{ height: "100%" }}
      bodyStyle={{ padding: "16px 4px" }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {isLoadingReliquat ? (
          <Loader />
        ) : reliquatCount !== NaN && reliquatCount > 0 ? (
          <p
            style={{
              fontSize: "2rem",
              margin: 0,
              color: "red",
            }}
          >
            {reliquatCount}
          </p>
        ) : (
          <p
            style={{
              fontSize: "2rem",
              margin: 0,
              color: "green",
            }}
          >
            {reliquatCount}
          </p>
        )}
        {allArticlesByClientByBonDeCommande &&
        allArticlesByClientByBonDeCommande.length ? (
          <Button type="ghost" onClick={showModalReliquat}>
            {t("Plus d'infos")}
          </Button>
        ) : (
          ""
        )}
      </div>

      <ModalReliquat
        articlesReliquat={allArticlesByClientByBonDeCommande}
        reliquatCount={reliquatCount}
        open={isModalReliquatOpen}
        onOk={handleCloseModal}
      />
    </Card>
  );
};

export default DashboardClientReliquats;
