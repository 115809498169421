import { Card, Col, Row } from "antd";
import { Fragment, useContext, useEffect, useState } from "react";
import MainContext from "../../contexts/MainContext";
import { Loader } from "../loader";
import { useTranslation } from "react-i18next";

const DashboardClientCA = ({ factureAnneeEnCours, factureAnneeMoinsUn }) => {
  const { viewportWidth, isLoadingCA } = useContext(MainContext);
  const [isBetterAnneeEnCours, setIsBetterAnneeEnCours] = useState(null);
  const [detailsCAAnneeEnCours, setDetailsCAAnneeEnCours] = useState({
    CAHT: 0,
    CATTC: 0,
    montantRegle: 0,
    TVA: 0,
    fraisDePort: 0,
  });
  const [detailsCAAnneeMoinsUn, setDetailsCAAnneeMoinsUn] = useState({
    CAHT: 0,
    CATTC: 0,
    montantRegle: 0,
    TVA: 0,
    fraisDePort: 0,
  });

  const { t } = useTranslation();

  useEffect(() => {
    calculBestAnnee(factureAnneeEnCours, factureAnneeMoinsUn);
    calculTotalCAAnneeEnCours(factureAnneeEnCours);
    calculTotalCAAnneeMoinsUn(factureAnneeMoinsUn);
  }, [factureAnneeEnCours, factureAnneeMoinsUn]);

  const calculTotalCAAnneeMoinsUn = (tab) => {
    let totalCATTC = 0;
    let totalCAHT = 0;
    let totalMontantRegle = 0;
    let montantTVA = 0;
    let fraisDePort = 0;

    tab.forEach((facture) => {
      totalCAHT += facture.MontantHT;
      totalCATTC += facture.NetAPayer;
      totalMontantRegle += facture.MontantRegle;
      fraisDePort += facture.FraisExpedition;

      if (facture.ExpeditionTaux1 !== 0) {
        montantTVA += (facture.MontantHT * facture.ExpeditionTaux1) / 100;
      }
    });
    setDetailsCAAnneeMoinsUn((prev) => ({
      ...prev,
      CAHT: totalCAHT,
      CATTC: totalCATTC,
      montantRegle: totalMontantRegle,
      TVA: montantTVA,
      fraisDePort: fraisDePort,
    }));
  };

  const calculTotalCAAnneeEnCours = (tab) => {
    let totalCATTC = 0;
    let totalCAHT = 0;
    let totalMontantRegle = 0;
    let montantTVA = 0;
    let fraisDePort = 0;

    tab.forEach((facture) => {
      totalCAHT += facture.MontantHT;
      totalCATTC += facture.NetAPayer;
      totalMontantRegle += facture.MontantRegle;
      fraisDePort += facture.FraisExpedition;

      if (facture.ExpeditionTaux1 !== 0) {
        montantTVA += (facture.MontantHT * facture.ExpeditionTaux1) / 100;
      }
    });
    setDetailsCAAnneeEnCours((prev) => ({
      ...prev,
      CAHT: totalCAHT,
      CATTC: totalCATTC,
      montantRegle: totalMontantRegle,
      TVA: montantTVA,
      fraisDePort: fraisDePort,
    }));
  };

  const calculBestAnnee = (tabAnnee, tabAnneeMoinsUn) => {
    if (tabAnnee.length && tabAnneeMoinsUn.length) {
      if (detailsCAAnneeEnCours.CAHT > detailsCAAnneeMoinsUn.CAHT) setIsBetterAnneeEnCours(true);
      else setIsBetterAnneeEnCours(false);
    }
  };

  return (
    <Fragment>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <Card
          title={
            viewportWidth < 1081 ? (
              <div>
                <p style={{ margin: 0, wordWrap: "break-word", whiteSpace: "normal" }}>
                  {t("CA (N-1)")}
                </p>
              </div>
            ) : (
              <div>
                <p style={{ margin: 0, wordWrap: "break-word", whiteSpace: "normal" }}>
                  {t("CHIFFRE D'AFFAIRE ANNEE -1")}
                </p>
              </div>
            )
          }
          style={{
            height: "100%",
          }}
          bodyStyle={
            viewportWidth < 1181
              ? {
                  padding: "16px 16px",
                  textAlign: "center",
                  fontSize: "1.2rem",
                }
              : {
                  padding: "16px 48px",
                  textAlign: "center",
                  fontSize: "1.6rem",
                }
          }>
          {isLoadingCA ? (
            <Loader />
          ) : (
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    margin: 0,
                    color: "#1890ff",
                  }}>
                  CA HT :{" "}
                </p>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "0.8rem",
                    fontWeight: "lighter",
                    margin: 0,
                  }}>
                  TVA :{" "}
                </p>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "0.8rem",
                    fontWeight: "lighter",
                    margin: 0,
                  }}>
                  Frais de port :
                </p>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    margin: "12px 0 0 0",
                    color: "#1890ff",
                    borderTop: "solid 1px #1890ff",
                  }}>
                  CA TTC :
                </p>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "0.8rem",
                    fontWeight: "lighter",
                    margin: "auto 0 0 0",
                  }}>
                  Montant reglé :
                </p>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    margin: 0,
                    color: "#1890ff",
                  }}>
                  {detailsCAAnneeMoinsUn.CAHT.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                  €
                </p>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "0.8rem",
                    fontWeight: "lighter",
                    margin: 0,
                  }}>
                  {detailsCAAnneeMoinsUn.TVA.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                  €
                </p>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "0.8rem",
                    fontWeight: "lighter",
                    margin: 0,
                  }}>
                  {detailsCAAnneeMoinsUn.fraisDePort.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                  €
                </p>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    margin: "12px 0 0 0",
                    color: "#1890ff",
                    borderTop: "solid 1px #1890ff",
                  }}>
                  {detailsCAAnneeMoinsUn.CATTC.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                  €
                </p>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "0.8rem",
                    fontWeight: "bold",
                    margin: 0,
                    color:
                      detailsCAAnneeMoinsUn.montantRegle.toFixed(2) ===
                      detailsCAAnneeMoinsUn.CATTC.toFixed(2)
                        ? "green"
                        : "red",
                  }}>
                  {detailsCAAnneeMoinsUn.montantRegle.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                  €
                </p>
              </Col>
            </Row>
          )}
        </Card>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <Card
          title={
            viewportWidth < 1081 ? (
              <div>
                <p style={{ margin: 0, wordWrap: "break-word", whiteSpace: "normal" }}>
                  {t("CA (EN COURS)")}
                </p>
              </div>
            ) : (
              <div>
                <p style={{ margin: 0, wordWrap: "break-word", whiteSpace: "normal" }}>
                  {t("ANNÉE EN COURS")}
                </p>
              </div>
            )
          }
          style={{ height: "100%" }}
          bodyStyle={
            viewportWidth < 1181
              ? {
                  padding: "16px 16px",
                  textAlign: "center",
                  fontSize: "1.2rem",
                }
              : {
                  padding: "16px 48px",
                  textAlign: "center",
                  fontSize: "1.6rem",
                }
          }>
          {isLoadingCA ? (
            <Loader />
          ) : (
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    margin: 0,
                    color: "#1890ff",
                  }}>
                  CA HT :{" "}
                </p>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "0.8rem",
                    fontWeight: "lighter",
                    margin: 0,
                  }}>
                  TVA :{" "}
                </p>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "0.8rem",
                    fontWeight: "lighter",
                    margin: 0,
                  }}>
                  Frais de port :
                </p>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    margin: "12px 0 0 0",
                    color: "#1890ff",
                    borderTop: "solid 1px #1890ff",
                  }}>
                  CA TTC :
                </p>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "0.8rem",
                    fontWeight: "lighter",
                    margin: "auto 0 0 0",
                  }}>
                  Montant reglé :
                </p>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    margin: 0,
                    color: isBetterAnneeEnCours ? "green" : "red",
                  }}>
                  {detailsCAAnneeEnCours.CAHT.toFixed(2)}€
                </p>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "0.8rem",
                    fontWeight: "lighter",
                    margin: 0,
                  }}>
                  {detailsCAAnneeEnCours.TVA.toFixed(2)}€
                </p>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "0.8rem",
                    fontWeight: "lighter",
                    margin: 0,
                  }}>
                  {detailsCAAnneeEnCours.fraisDePort.toFixed(2)}€
                </p>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    margin: "12px 0 0 0",
                    color: "#1890ff",
                    borderTop: "solid 1px #1890ff",
                  }}>
                  {detailsCAAnneeEnCours.CATTC.toFixed(2)}€
                </p>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "0.8rem",
                    fontWeight: "bold",
                    margin: 0,
                    color:
                      detailsCAAnneeEnCours.montantRegle.toFixed(2) ===
                      detailsCAAnneeEnCours.CATTC.toFixed(2)
                        ? "green"
                        : "red",
                  }}>
                  {detailsCAAnneeEnCours.montantRegle.toFixed(2)}€
                </p>
              </Col>
            </Row>
          )}
        </Card>
      </Col>
    </Fragment>
  );
};

export default DashboardClientCA;
