import { Button, Col, Divider, Modal, Row } from "antd";
import { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import MainContext from "../../contexts/MainContext";

const ModalBestSeller = ({ open, onOk, bests }) => {
  const { viewportWidth } = useContext(MainContext);
  const { t } = useTranslation();

  return (
    <Modal
      title="BEST SELLER | TOP 10"
      open={open}
      onOk={onOk}
      closable={true}
      onCancel={onOk}
      footer={[
        <Button key="back" onClick={onOk}>
          {t("Fermer")}
        </Button>,
      ]}
      width={viewportWidth < 1081 ? viewportWidth - 50 : viewportWidth - 300}
    >
      <Row>
        <Col xs={18} sm={18} md={18} lg={18} xl={18}>
          {bests?.length > 1 ? "Articles" : "Article"}
        </Col>
        <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "right" }}>
          {t("Qté")}
        </Col>
      </Row>
      <Divider style={{ marginTop: "12px" }} />
      {bests?.length
        ? bests.map((b) => (
            <Fragment key={b.Id}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p>{b.Designation}</p>
                <p>{b.Quantite}</p>
              </div>
              <Divider />
            </Fragment>
          ))
        : ""}
    </Modal>
  );
};

export default ModalBestSeller;
