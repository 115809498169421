import { Tag } from "antd";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import MainContext from "../../contexts/MainContext";

const PastilleDispo = ({ stockProduct, loadingStock, error }) => {
  const { t } = useTranslation();
  const { viewportWidth } = useContext(MainContext);

  return loadingStock ? (
    "..."
  ) : error ? (
    viewportWidth < 1090 ? (
      <Tag color="red">&#x25CF;</Tag>
    ) : (
      <Tag color="red">{t("Indisponible")}</Tag>
    )
  ) : stockProduct > 5 ? (
    viewportWidth < 1090 ? (
      <Tag color="green">&#x25CF;</Tag>
    ) : (
      <Tag color="green">{t("Disponible")}</Tag>
    )
  ) : viewportWidth < 1090 ? (
    <Tag color="red">&#x25CF;</Tag>
  ) : (
    <Tag color="red">{t("Indisponible")}</Tag>
  );
};

export default PastilleDispo;
