import { Button, Col, Divider, Modal, Row } from "antd";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

const ModalAgentFacturesImpayees = ({
  open,
  onOk,
  factures,
  viewportWidth,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t("FACTURES IMPAYÉES")}
      width={viewportWidth < 1081 ? viewportWidth - 50 : viewportWidth - 300}
      open={open}
      onOk={onOk}
      onCancel={onOk}
      closable={true}
      footer={[
        <Button key="back" onClick={onOk}>
          {t("Fermer")}
        </Button>,
      ]}
    >
      <Row>
        <Col xs={10} sm={10} md={10} lg={10} xl={10}>
          {t("Intitulé du magasin")}
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2}>
          Ref. Cmd
        </Col>
        <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "right" }}>
          {t("Montant")}
        </Col>
        <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "right" }}>
          Date
        </Col>
      </Row>
      <Divider style={{ marginTop: "12px" }} />
      <Row>
        {factures?.length > 0
          ? factures.map((b) => (
              <Fragment key={b.NumeroDocument}>
                <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                  {b.Intitule}
                </Col>
                <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                  {b.NumeroDocument}
                </Col>
                <Col
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  style={{ textAlign: "right" }}
                >
                  {(
                    parseFloat(b.NetAPayer) - parseFloat(b.MontantRegle)
                  ).toFixed(1)}
                  €
                </Col>
                <Col
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  style={{ textAlign: "right" }}
                >
                  {
                    new Date(
                      parseInt(b.DateCreation.split("(")[1].split(")")[0])
                    )
                      .toLocaleString()
                      .split(" ")[0]
                  }
                </Col>
                <Divider />
              </Fragment>
            ))
          : ""}
      </Row>
    </Modal>
  );
};

export default ModalAgentFacturesImpayees;
