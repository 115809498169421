import { Button, Card } from "antd";
import { Loader } from "../loader";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { ModalImpayes } from "../modal-client-impayes";

const DashboardClientImpaye = ({ montantImpaye, tabFacturesImpayes }) => {
  const { t } = useTranslation();
  const [isModalImpayesOpen, setIsModalImpayesOpen] = useState(false);

  useEffect(() => {
    console.log("tab factures impayes: ", tabFacturesImpayes);
  }, [tabFacturesImpayes]);

  const showModalImpayes = () => {
    setIsModalImpayesOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalImpayesOpen(false);
  };

  return (
    <Card
      title={t("RESTANT DÛ")}
      style={{ height: "100%" }}
      bodyStyle={{ padding: "16px 4px" }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {montantImpaye === null ? (
          <Loader />
        ) : montantImpaye && montantImpaye > 0 ? (
          <p
            style={{
              fontSize: "2rem",
              margin: 0,
              color: "red",
            }}
          >
            {montantImpaye.toFixed(2)} €
          </p>
        ) : (
          <p
            style={{
              fontSize: "2rem",
              margin: 0,
              color: "green",
            }}
          >
            {montantImpaye.toFixed(2)} €
          </p>
        )}
        {tabFacturesImpayes && tabFacturesImpayes.length ? (
          <Button type="ghost" onClick={showModalImpayes}>
            {t("Plus d'infos")}
          </Button>
        ) : (
          ""
        )}
      </div>
      <ModalImpayes
        tabFacturesImpayes={tabFacturesImpayes}
        open={isModalImpayesOpen}
        onOk={handleCloseModal}
      />
    </Card>
  );
};

export default DashboardClientImpaye;
