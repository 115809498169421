import { Button, Card } from "antd";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MainContext from "../../contexts/MainContext";
import { Loader } from "../loader";
import { ModalBestSeller } from "../modal-client-best-seller";
import { useTranslation } from "react-i18next";

const DashboardClientBestSeller = () => {
  const [isModalReliquatOpen, setIsModalReliquatOpen] = useState(false);

  const {
    getAllArticlesByDocTypeByClient,
    allArticlesByClientByFacture,
    isLoadingBestSeller,
    viewportWidth,
  } = useContext(MainContext);
  const { idClient } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    getAllArticlesByDocTypeByClient(idClient, 7);
  }, [idClient]);

  const showModalReliquat = () => {
    setIsModalReliquatOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalReliquatOpen(false);
  };

  return (
    <Card
      title={
        viewportWidth < 1081
          ? t("BEST SELLER")
          : t("BEST SELLER (ANNÉE EN COURS)")
      }
      style={{ height: "100%" }}
      bodyStyle={{ padding: "16px 4px" }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {isLoadingBestSeller ? (
          <Loader />
        ) : allArticlesByClientByFacture &&
          allArticlesByClientByFacture.length ? (
          <>
            <p
              style={{
                textAlign: "center",
                fontSize: "1rem",
                margin: 0,
              }}
            >
              {allArticlesByClientByFacture &&
              allArticlesByClientByFacture[0]?.Designation
                ? allArticlesByClientByFacture[0]?.Designation.split("-")[0]
                : ""}
            </p>
            <p style={{ textAlign: "center", fontSize: "2rem", margin: 0 }}>
              {allArticlesByClientByFacture &&
              allArticlesByClientByFacture[0]?.Quantite
                ? allArticlesByClientByFacture[0]?.Quantite
                : ""}
            </p>
            {allArticlesByClientByFacture &&
            allArticlesByClientByFacture.length ? (
              <Button type="ghost" onClick={showModalReliquat}>
                {t("Plus d'infos")}
              </Button>
            ) : (
              ""
            )}
          </>
        ) : (
          <p
            style={{
              textAlign: "center",
              fontSize: "1rem",
              margin: 0,
            }}
          >
            N/A
          </p>
        )}
      </div>
      <ModalBestSeller
        open={isModalReliquatOpen}
        onOk={handleCloseModal}
        bests={allArticlesByClientByFacture}
      />
    </Card>
  );
};

export default DashboardClientBestSeller;
